import { create } from "apisauce";

// Enviroment Variables
const { REACT_APP_API_URI, REACT_APP_API_TOKEN} = process.env;

const api = create({
  baseURL: REACT_APP_API_URI,
  timeout: 120000,
  headers: {
    "AppToken": REACT_APP_API_TOKEN,
    "accept": "text/plain"
  }
});

export default api;
