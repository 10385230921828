import { useEffect } from "react";
import { toast } from "react-toastify";

import api from "../../app/api";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { logout } from "../../features/auth/authSlice";

import { useAdminPingMutation } from "../../features/auth/useAdminPingMutation";
import { useRecycleMutation } from "../../features/auth/useRecycleMutation";
import { useLimitedPingMutation } from "../../features/auth/useLimitedPingMutation";

interface Props {
  children: any;
}

export default function ApiMonitorLayout({ children }: Props) {
  const currentUser = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(logout());
    toast.info("Sesión expirada");
  };

  const recycleMutation = useRecycleMutation(undefined, undefined, onLogout);

  const adminPingMutation = useAdminPingMutation(undefined, () => {
    recycleMutation.mutate();
  });

  const limitedPingMutation = useLimitedPingMutation(undefined, () => {
    adminPingMutation.mutate();
  });


  useEffect(() => {
    api.addMonitor(async (response) => {
      if (
        response.status === 401 &&
        (!response?.config?.url?.includes("auth/login") &&
          !response?.config?.url?.includes("auth/limitedping") &&
          !response?.config?.url?.includes("admin/idcardtypes") &&
          !response?.config?.url?.includes("auth/logout"))
      ) {
        limitedPingMutation.mutate({ user: currentUser.user as any });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
}
