// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";
import { fetchingModelDefaults } from "../tickets/types";

// Hooks & actions imports
import { useAppSelector } from "../../app/hook";

interface paramsType {
  id: number;
  notificationID: string | number;
}

export function useDeleteInvoiceMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  const queryClient = useQueryClient();
  const currentUser = useAppSelector((state) => state.auth);

  async function deleteInvoice(params: paramsType): Promise<any> {
    const response = await api.delete<any>(`invoices/invoices/${params.id}`);

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: deleteInvoice,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          "TicketsInfinite",
          ...Object.values({
            ...fetchingModelDefaults,
            storeID:
              !!currentUser.user?.companyID &&
              currentUser.user?.companyID !== -1
                ? currentUser.user.companyID
                : 0,
          }),
        ],
        refetchType: "all",
      });
      queryClient.invalidateQueries(["Invoice", variables.id]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
