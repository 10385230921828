// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";

export function useAdminPingMutation(
  onSuccessMutation?: () => void,
  onErrorMutation?: (err: ApiErrorResponse<any>) => void
) {
  async function adminPing(): Promise<any> {
    const response = await api.get<any>("admin/ping");

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: adminPing,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
