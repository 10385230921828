// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";

export function useRecycleMutation(
  onSuccessMutation?: () => void,
  onErrorMutation?: (err: ApiErrorResponse<any>) => void,
  onSettledMutation?: () => void
) {
  async function recycle(): Promise<any> {
    const response = await api.post<any>("platform/recycle");

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: recycle,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
    onSettled: onSettledMutation,
  });
}
