// React imports
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

// Custom components
import DataPersonForm from "./DataPersonForm";
import DataInvoiceForm from "./DataInvoiceForm";
import CreateInvoiceActions from "./CreateInvoiceActions";
import SettlementTaxesAndCommissions from "../../../shared/SettlementTaxesAndCommissions";
import ContentWithToggle from "../../../shared/ContentWithToggle";
import { fetchingModelDefaults } from "../../../../features/tickets/types";

// Hooks Redux imports
import { useAppSelector } from "../../../../app/hook";

// Custom types imports
import {
  FormDataCreateInvoice,
  defaultValues,
} from "../../../../features/invoices/types";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Third components & helpers imports
import { toast } from "react-toastify";

// Hooks & components (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom queries imports
import { useSendInvoiceMutation } from "../../../../features/invoices/useSendInvoiceMutation";
import { useCreateInvoiceMutation } from "../../../../features/invoices/useCreateInvoiceMutation";
import { useCreateUserAndInvoice } from "../../../../features/invoices/useCreateUserAndInvoice";

function CreateInvoice() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);
  const methods = useForm<FormDataCreateInvoice>({
    defaultValues: {
      ...defaultValues,
      invoice: {
        ...defaultValues.invoice,
        storeID: currentUser?.companyID || 0,
      },
    },
  });

  const [existingPerson, setExistingPerson] = useState(false);

  const sendInvoiceByEmailMutation = useSendInvoiceMutation();

  const createInvoiceMutation = useCreateInvoiceMutation(
    async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [
          "TicketsInfinite",
          ...Object.values({
            ...fetchingModelDefaults,
            storeID:
              !!currentUser?.companyID && currentUser?.companyID !== -1
                ? currentUser.companyID
                : 0,
          }),
        ],
        refetchType: "all",
      });
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Cobro creado",
        autoClose: 5000,
      });
      sendInvoiceByEmailMutation.mutate({ invoice: data, notificationID: "" });
      window.location.replace(
        `/${data.idCard}/cobros/c/${data.invoiceID}?accessCode=${data.accessCode}`
      );
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar crear el cobro",
        autoClose: 5000,
      });
    }
  );

  const createUserAndInvoice = useCreateUserAndInvoice(
    methods.watch("person"),
    methods.watch("invoice")
  );

  const onSubmit = methods.handleSubmit((data) => {
    if (existingPerson) {
      const notificationID = toast.loading("Creando cobro...");
      createInvoiceMutation.mutate({
        invoice: {
          ...data.invoice,
          date: new Date().toISOString(),
        },
        notificationID,
      });
    } else {
      const notificationID = toast.loading("1/3 Validando email...");
      createUserAndInvoice.mutate({
        email: data.person.email,
        notificationID,
      });
    }
  });

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <ContentWithToggle
            title="Generar cobro"
            onBackButton={() => navigate(`/${currentUser?.id}/cobros`)}
            isToogle={true}
          >
            <DataInvoiceForm />
          </ContentWithToggle>

          <ContentWithToggle title="Datos del cliente" isToogle={true}>
            <DataPersonForm
              existingPerson={existingPerson}
              setExistingPerson={setExistingPerson}
            />
          </ContentWithToggle>

          <SettlementTaxesAndCommissions />
          <CreateInvoiceActions />
        </form>
      </FormProvider>
    </div>
  );
}
export default CreateInvoice;
